import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';
import { EnvironmentTwoTone } from '@ant-design/icons';
import Map, {
  NavigationControl,
  GeolocateControl,
  Marker,
  MapRef,
  MapLayerMouseEvent,
} from 'react-map-gl/maplibre';
import {
  MapAuthenticationOptions,
  withIdentityPoolId,
} from '@aws/amazon-location-utilities-auth-helper';
import {
  GeoSearchResult,
  useLocationService,
} from '../../service/location.service';
import { RootState } from '../../store/store';

import 'maplibre-gl/dist/maplibre-gl.css';

type Props = {
  pin?: { lng: number; lat: number };
  onPinMap: (value: GeoSearchResult) => void;
};

const InteractiveMap: FunctionComponent<Props> = ({ pin, onPinMap }) => {
  const locationService = useLocationService();
  const { loadStack } = useSelector((state: RootState) => state.app);
  const mapRef = useRef<MapRef>(null);

  const [mapConfig, setMapConfig] = useState<
    MapAuthenticationOptions & { mapStyle: string }
  >();
  const [marker, setMarker] = useState<{
    latitude: number;
    longitude: number;
  }>();

  const isSearchingLngLat = loadStack.includes('location/search/point');

  useEffect(() => {
    (async () => {
      const config = await locationService.getMapConfig();
      const authHelper = await withIdentityPoolId(config.auth);
      setMapConfig({
        ...authHelper.getMapAuthenticationOptions(),
        mapStyle: config.mapStyle,
      });
    })();
  }, []);

  useEffect(() => {
    if (pin?.lat != null && pin?.lng != null) {
      setMarker({
        latitude: pin.lat,
        longitude: pin.lng,
      });
      mapRef.current?.flyTo({ center: [pin.lng, pin.lat], zoom: 15 });
    }
  }, [pin]);

  const handlePinMap = async (event: MapLayerMouseEvent) => {
    const { lng, lat } = event.lngLat;
    setMarker({
      latitude: lat,
      longitude: lng,
    });

    const result = await locationService.searchLngLat(lng, lat);
    const [closestPoint] = result.sort((a, b) => a.distance - b.distance);
    if (closestPoint) {
      onPinMap(closestPoint);
    }
  };

  return !mapConfig ? null : (
    <Spin spinning={isSearchingLngLat}>
      <Map
        ref={mapRef}
        initialViewState={{
          // Malaysia coords
          latitude: 4.2105,
          longitude: 101.9758,
          zoom: 7,
        }}
        style={{ height: '225px', width: '100%' }}
        {...mapConfig}
        onClick={handlePinMap}
        attributionControl={false}
      >
        {marker && (
          <Marker {...marker}>
            <EnvironmentTwoTone
              style={{ fontSize: '2.5rem' }}
              twoToneColor={'red'}
            />
          </Marker>
        )}
        <NavigationControl position='bottom-right' showZoom showCompass />
        <GeolocateControl />
      </Map>
    </Spin>
  );
};

export default InteractiveMap;
