import { Form, Input, Button, FormProps } from 'antd';
import React, { FC, useEffect } from 'react';
import { Div } from '../../framework';
import PhoneNumberInput, {
  PhoneNumberInputValue,
  phoneNumberInputValidator,
} from '../PhoneNumberInput/PhoneNumberInput';

export type UserInfoValues = {
  name: string;
  email: string;
  contactNumber: PhoneNumberInputValue;
};

type Props = FormProps & {
  onFinish?: (values: UserInfoValues) => void;
  values?: UserInfoValues;
  formError?: string;
  submitBtnText?: string;
  disabledEmail?: boolean;
};

const UserInfo: FC<Props> = ({
  values,
  formError,
  submitBtnText,
  form: propForm,
  disabledEmail,
  ...props
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (values) {
      const currentForm = propForm ?? form;
      currentForm.setFieldsValue(values);
    }
  }, [values]);

  return (
    <Form {...props} layout='vertical' form={propForm ?? form}>
      <Form.Item
        name='name'
        label='Name'
        rules={[
          {
            required: true,
            message: 'Please input a name',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name='email'
        label='Email'
        rules={[
          {
            required: true,
            message: 'Please input an email address',
          },
          {
            type: 'email',
            message: 'This is not a valid email',
          },
        ]}
      >
        <Input
          disabled={disabledEmail}
          onInput={(e) => {
            const { value, selectionStart, selectionEnd } = e.currentTarget;
            e.currentTarget.value = value.toLowerCase();
            e.currentTarget.setSelectionRange(selectionStart, selectionEnd); // to preserve cursor position
          }}
        />
      </Form.Item>
      <Form.Item
        name='contactNumber'
        label='Contact Number'
        rules={[
          { required: true },
          {
            validator: phoneNumberInputValidator({
              emptyError: 'Please input a contact number',
            }),
          },
        ]}
        initialValue={{
          phoneNumberPrefix: '+60',
        }}
      >
        <PhoneNumberInput />
      </Form.Item>

      <Div style={{ color: 'red' }}>{formError}</Div>
      <Form.Item>
        <Button type='primary' htmlType='submit' size='large' block>
          {submitBtnText ?? 'Continue'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default UserInfo;
