import React, { FunctionComponent } from 'react';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';
import './GlobalLoader.scss';
import { RootState } from '../../store/store';

// TODO: framer-motion animate overlay

const GlobalLoader: FunctionComponent = () => {
  const { intLoadStack } = useSelector((state: RootState) => state.app);
  const isLoading = intLoadStack.length > 0;

  return isLoading ? (
    <div className='component-overlay'>
      <Spin size='large' />
    </div>
  ) : null;
};

export default GlobalLoader;
