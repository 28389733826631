import React from 'react';
import { FC } from 'react';
import { format } from 'date-fns';
import { activityActionType as actionType } from '../../helper/constants';
import { formatIsoDate } from '../../helper/object';
import { Activity } from '../../helper/type';
import { featureToggle } from '../../../config/app.config';

type ItemText = Record<string, (act: Activity) => React.ReactNode>;
const pending: ItemText = {
  [actionType.hirerConfirmApplication]: (act) => (
    <>
      Your application on{' '}
      <span className='bold'>
        {act.projectTitle} - {act.roleName}
      </span>{' '}
      has been accepted, reply to confirm your job!
    </>
  ),
  [actionType.casting]: (act) => (
    <>
      You&apos;ve been invited to cast the role{' '}
      <span className='bold'>
        {act.projectTitle} - {act.roleName}
      </span>
      , reply to accept this invitation!
    </>
  ),
  [actionType.fitting]: (act) => (
    <>
      You&apos;ve been invited for fitting role{' '}
      <span className='bold'>
        {act.projectTitle} - {act.roleName}
      </span>
      , reply to accept this invitation!
    </>
  ),
};
const scheduled: ItemText = {
  [actionType.casting]: (act) => (
    <>
      Scheduled casting role{' '}
      <span className='bold'>
        {act.projectTitle} - {act.roleName}
      </span>{' '}
      on{' '}
      <span className='bold'>
        {act.scheduledDatetime ? formatIsoDate(act.scheduledDatetime) : '(TBC)'}
      </span>
    </>
  ),
  [actionType.fitting]: (act) => (
    <>
      Scheduled fitting role{' '}
      <span className='bold'>
        {act.projectTitle} - {act.roleName}
      </span>{' '}
      on{' '}
      <span className='bold'>
        {act.scheduledDatetime ? formatIsoDate(act.scheduledDatetime) : '(TBC)'}
      </span>
    </>
  ),
  [actionType.talentAcceptConfirmation]: (act) => (
    <>
      Scheduled application{' '}
      <span className='bold'>
        {act.projectTitle} - {act.roleName}
      </span>{' '}
      on{' '}
      <span className='bold'>
        {act.scheduledDatetime ? formatIsoDate(act.scheduledDatetime) : '(TBC)'}
      </span>
    </>
  ),
};
const other: ItemText = {
  [actionType.hirerCancelCasting]: (act) => (
    <>
      Hirer has cancelled Casting session on role{' '}
      <span className='bold'>
        {act.projectTitle} - {act.roleName}
      </span>{' '}
      {act.cancelReason === 'reschedule'
        ? 'due to the need of rescheduling'
        : ''}
    </>
  ),
  [actionType.hirerShortlistApplicant]: (act) => (
    <>
      You&apos;ve been shortlisted on role{' '}
      <span className='bold'>
        {act.projectTitle} - {act.roleName}
      </span>{' '}
      !
    </>
  ),
  [actionType.talentRejectConfirmation]: (act) => (
    <>
      Job confirmation of{' '}
      <span className='bold'>
        {act.projectTitle} - {act.roleName}
      </span>{' '}
      has been rejected
    </>
  ),
  [actionType.talentRejectCasting]: (act) => (
    <>
      Rejected Casting invitation on role{' '}
      <span className='bold'>
        {act.projectTitle} - {act.roleName}
      </span>
    </>
  ),
  [actionType.talentRejectFitting]: (act) => (
    <>
      Rejected Fitting invitation on role{' '}
      <span className='bold'>
        {act.projectTitle} - {act.roleName}
      </span>
    </>
  ),
  [actionType.viewedComcard]: (act) =>
    other[actionType.viewedCastingVideo]?.call(null, act),
  [actionType.viewedProfile]: (act) =>
    other[actionType.viewedCastingVideo]?.call(null, act),
  [actionType.viewedCastingVideo]: (act) => (
    <>
      Your application{' '}
      <span className='bold'>
        {act.projectTitle} - {act.roleName}
      </span>{' '}
      has been viewed
    </>
  ),
  [actionType.talentWithdrawApplication]: (act) => (
    <>
      Application withdrawal on role{' '}
      <span className='bold'>
        {act.projectTitle} - {act.roleName}
      </span>
    </>
  ),
  [actionType.talentReceivePayment]: (act) => {
    if (!featureToggle.invoice) return;

    const dummyData = {
      amount: 200,
      shootDate: '2024-05-17T03:13:07.318Z',
    };
    return (
      <>
        <span className='bold'>You got paid!</span>
        <br />
        Payment of RM {dummyData.amount} for {act.projectTitle} shoot on{' '}
        {format(new Date(dummyData.shootDate), 'dd MMM yyyy')} has been credited
        to your bank account.
      </>
    );
  },
};

type Props = {
  act: Activity;
  type: 'pending' | 'scheduled' | 'other';
};

const ActivityItemText: FC<Props> = ({ act, type }) => {
  const actType = act.actionType as keyof ItemText;
  if (type === 'pending' && pending[actType]) {
    return <>{pending[actType](act)}</>;
  }
  if (type === 'scheduled' && scheduled[actType]) {
    return <>{scheduled[actType](act)}</>;
  }
  if (type === 'other' && other[actType]) {
    return <>{other[actType](act)}</>;
  }

  return <></>;
};

export default ActivityItemText;
