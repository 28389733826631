import { Button, Card, Tag, Typography } from 'antd';
import React, { FC } from 'react';
import { Div } from '../../../framework';
import { HirerUser } from '../../../helper/type';

import './HirerUserCard.scss';

const { Paragraph } = Typography;

type Props = {
  user?: Partial<HirerUser>;
  onDelete?: () => void;
};

const HirerUserCard: FC<Props> = ({ user, onDelete }) => {
  return (
    <Card className='hirer-user-card'>
      <Paragraph className='bold user-name'>
        {user?.name}{' '}
        <Tag
          color={user?.status === 'verified' ? '#16B55E' : '#444444'}
          style={{ textTransform: 'capitalize', borderRadius: '4px' }}
        >
          {user?.status ?? 'pending'}
        </Tag>
      </Paragraph>

      <Paragraph>
        <Div className='bold'>Email</Div>
        <Div>{user?.email}</Div>
      </Paragraph>

      <Paragraph>
        <Div className='bold'>Contact Number</Div>
        <Div>
          {user?.phoneNumberPrefix}
          {user?.phoneNumber}
        </Div>
      </Paragraph>

      <Div>
        <Button type='text' className='delete-button' onClick={onDelete}>
          Delete user
        </Button>
      </Div>
    </Card>
  );
};

export default HirerUserCard;
