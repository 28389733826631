import React, { FC, useEffect, useState } from 'react';
import { Form, Input, Button, FormProps, FormInstance, Select } from 'antd';
import PhoneNumberInput, {
  PhoneNumberInputValue,
  phoneNumberInputValidator,
} from '../PhoneNumberInput/PhoneNumberInput';
import { Div } from '../../framework';
import { HirerCompany } from '../../helper/type';
import { countries, malaysiaStates } from '../../../constant/region';

export type CompanyInfoValues = {
  companyName: string;
  companySsmRegNumber: string;
  companyContactNumber: PhoneNumberInputValue;
  companyEmail: string;
  companyCountry: string;
  companyAddress: string;
  companyAddressPostcode: number;
  companyAddressCity: string;
  companyAddressState: string;
};

export const getFormFields = (form: FormInstance): Partial<HirerCompany> => {
  const formValues: CompanyInfoValues = form.getFieldsValue();
  return {
    ...formValues,
    companyContactNumber: formValues.companyContactNumber.phoneNumber,
    companyContactNumberPrefix:
      formValues.companyContactNumber.phoneNumberPrefix,
  };
};

export const setFormFields = (
  form: FormInstance,
  values: Partial<HirerCompany>
) => {
  form.setFieldsValue(values);
  form.setFieldValue('companyContactNumber', {
    phoneNumberPrefix: values?.companyContactNumberPrefix ?? '+60',
    phoneNumber: values?.companyContactNumber,
  });
};

type Props = FormProps & {
  onFinish?: (values: CompanyInfoValues) => void;
  formError?: string;
  values?: Partial<HirerCompany>;
};

const CompanyInfo: FC<Props> = ({
  formError,
  values,
  form: propForm,
  ...props
}) => {
  const [form] = Form.useForm();
  const currentForm = propForm ?? form;

  const [companyCountry, setCompanyCountry] = useState<string>();

  useEffect(() => {
    if (values) {
      setFormFields(currentForm, values);
    }
  }, [values]);

  return (
    <Form {...props} layout='vertical' form={currentForm}>
      <Form.Item
        name='companyName'
        label='Company Name'
        rules={[{ required: true, message: 'Please input your company name' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name='companySsmRegNumber'
        label='SSM Registration Number'
        help='* for Malaysian companies only'
      >
        <Input />
      </Form.Item>
      <Form.Item
        name='companyContactNumber'
        label='Company Contact Number'
        rules={[
          { required: true },
          {
            validator: phoneNumberInputValidator({
              emptyError: 'Please input your company contact number',
            }),
          },
        ]}
        initialValue={{
          phoneNumberPrefix: '+60',
        }}
      >
        <PhoneNumberInput />
      </Form.Item>
      <Form.Item
        name='companyEmail'
        label='Company Email'
        rules={[
          {
            required: true,
            message: 'Please input your company email address',
          },
          {
            type: 'email',
            message: 'This is not a valid email',
          },
        ]}
      >
        <Input
          onInput={(e) => {
            const { value, selectionStart, selectionEnd } = e.currentTarget;
            e.currentTarget.value = value.toLowerCase();
            e.currentTarget.setSelectionRange(selectionStart, selectionEnd); // to preserve cursor position
          }}
        />
      </Form.Item>
      <Form.Item
        name='companyCountry'
        label='Company based at...'
        rules={[{ required: true, message: 'Please select a country' }]}
      >
        <Select
          showSearch
          onChange={(val) => {
            if (
              (companyCountry === 'Malaysia' || val === 'Malaysia') &&
              companyCountry !== val
            ) {
              currentForm.setFieldValue('companyAddressState', '');
            }
            setCompanyCountry(val);
          }}
          options={countries.map((c) => ({ label: c, value: c }))}
        />
      </Form.Item>
      <Form.Item
        name='companyAddress'
        label='Company Address'
        rules={[
          {
            required: true,
            message: 'Please input your company address',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name='companyAddressPostcode'
        label='Postcode'
        rules={[
          {
            required: true,
            message: 'Please input your company address postcode',
          },
        ]}
      >
        <Input type='number' />
      </Form.Item>
      <Form.Item
        name='companyAddressCity'
        label='Company Address City'
        rules={[
          {
            required: true,
            message: 'Please input your company address city',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        hidden={currentForm.getFieldValue('companyCountry') === 'Malaysia'}
        name='companyAddressState'
        label='Company Address State'
        rules={[
          {
            required: true,
            message: 'Please input your company address state',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        hidden={currentForm.getFieldValue('companyCountry') !== 'Malaysia'}
        name='companyAddressState'
        label='Company Address State'
        rules={[
          {
            required: true,
            message: 'Please input your company address state',
          },
        ]}
      >
        <Select options={malaysiaStates.map((s) => ({ label: s, value: s }))} />
      </Form.Item>

      <Form.Item>
        <Div style={{ color: 'red' }}>{formError}</Div>
        <Button type='primary' htmlType='submit' size='large' block>
          Continue
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CompanyInfo;
