import React, { FC, forwardRef, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Rate, Tooltip, Typography } from 'antd';
import moment from 'moment';
import Icon, {
  PlayCircleOutlined,
  InstagramOutlined,
  ClockCircleOutlined,
  LogoutOutlined,
  CheckOutlined,
  SolutionOutlined,
  CalendarOutlined,
  FieldTimeOutlined,
  WarningOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { videoThumbnailPlaceholder } from '../../../../config/app.config';
import { RootState } from '../../../store/store';
import { openModal } from '../../../store/app.slice';
import { Div, Icon as HcmIcon } from '../../../framework';
import {
  getApplicantConfirmed,
  getApplicantWithdrawn,
  getCastingInvitation,
} from '../../../helper/activity/getApplicantActivities';
import { activityActionType, statusText } from '../../../helper/constants';
import {
  Activity,
  Applicant,
  GalleryItem,
  Project,
  RatingStats,
  Role,
} from '../../../helper/type';
import { formatIsoDate, formatNumber } from '../../../helper/object';
import { useActivityService } from '../../../service/activity.service';
import ApplicantCardAction from './ApplicantCardAction';
import { useRatingService } from '../../../service/rating.service';

import './ApplicantCard.scss';

const { Text, Link: AntLink } = Typography;

const { TikTokSvg } = HcmIcon;

const maxGalleryItemDisplay = 2;
const maxExperiencesDisplay = 4;

type Props = {
  applicant: Applicant;
  project?: Partial<Project>;
  role?: Partial<Role>;
  handleClickCallback?: () => void;
} & {
  ref?: React.Ref<HTMLDivElement>;
};

const ApplicantCard = forwardRef<HTMLDivElement, Props>(
  ({ applicant, project, role, handleClickCallback }, customRef) => {
    const dispatch = useDispatch();
    const ref = useMemo(() => customRef, []);

    const { authHirer } = useSelector((state: RootState) => state.user);
    const { mappedActivities } = useSelector((state: RootState) => state.hirer);
    const activityService = useActivityService();
    const ratingService = useRatingService();

    const [invitation, setInvitation] = useState<Activity>();
    const [confirmation, setConfirmation] = useState<Activity>();
    const [withdraw, setWithdraw] = useState<Activity>();
    const [ratingStats, setRatingStats] = useState<RatingStats>();

    const { roleId, talentId } = applicant;
    const { title: projectTitle = '' } = project || {};

    const applicantActivities = (mappedActivities[roleId] ?? {})[talentId];
    useEffect(() => {
      setInvitation(getCastingInvitation(roleId, talentId, mappedActivities));

      if (applicant.status === 'confirmed') {
        setConfirmation(
          getApplicantConfirmed(roleId, talentId, mappedActivities)
        );
      }

      if (applicant.status === 'withdrawn') {
        setWithdraw(getApplicantWithdrawn(roleId, talentId, mappedActivities));
      }
    }, [applicantActivities]);

    useEffect(() => {
      (async () => {
        const stats = await ratingService.getRatingStats(applicant.talentId);
        setRatingStats(stats);
      })();
    }, []);

    const addApplicantViewCountData = {
      applicationId: applicant.id,
      roleId: applicant.roleId,
      talentId: applicant.talentId,
      hirerId: authHirer.hirerId,
      projectTitle,
      roleName: role?.name ?? '',
    };
    const addApplicantViewCount = async (viewType: string) => {
      if (authHirer.hirerId && !withdraw) {
        await activityService.addApplicantViewCount(
          addApplicantViewCountData,
          viewType
        );
      }
    };

    const openGallery = (
      index = 0,
      gallery?: GalleryItem[],
      comcardCount = true
    ) => {
      dispatch(
        openModal({
          modal: 'media-gallery',
          config: {
            context: {
              gallery: gallery ?? applicant.gallery,
              index,
              ...(withdraw
                ? null
                : {
                    handleVideoPlay: {
                      name: 'addApplicantViewCount',
                      payload: addApplicantViewCountData,
                    },
                  }),
            },
          },
        })
      );

      if (comcardCount) addApplicantViewCount('comcard');
    };

    const renderApplicantStatus = () => {
      if (withdraw) {
        return (
          <Div flex className='align-center'>
            <LogoutOutlined />
            <span>{statusText['withdrawn']}</span>
          </Div>
        );
      }

      const isConfirmed = Boolean(confirmation);
      const { replyStatus: confReplyStatus } = confirmation ?? {};

      if (isConfirmed && confReplyStatus === 'PENDING') {
        return (
          <Div flex className='align-center'>
            <FieldTimeOutlined />
            <span>{statusText['pendingConfirmation']}</span>
          </Div>
        );
      }

      if (isConfirmed && confReplyStatus === 'SCHEDULED') {
        return (
          <Div flex className='align-center'>
            <CheckOutlined />
            <span>{statusText['confirmationAccepted']}</span>
          </Div>
        );
      }

      if (
        confirmation?.actionType === activityActionType.talentRejectConfirmation
      ) {
        return (
          <Div flex className='align-center'>
            <WarningOutlined />
            <span>{statusText['confirmationRejected']}</span>
          </Div>
        );
      }

      const isCasting = invitation?.actionType === activityActionType.casting;
      const { replyStatus: invReplyStatus } = invitation ?? {};

      if (isCasting && invReplyStatus === 'SCHEDULED') {
        return (
          <Div flex className='align-center'>
            <CalendarOutlined />
            <Tooltip
              title={
                invitation && invitation.scheduledDatetime
                  ? `Scheduled at ${formatIsoDate(
                      invitation.scheduledDatetime
                    )}`
                  : ''
              }
            >
              <span>{statusText['castingScheduled']}</span>
            </Tooltip>
          </Div>
        );
      }

      if (isCasting && invReplyStatus === 'PENDING') {
        return (
          <Div flex className='align-center'>
            <ClockCircleOutlined />
            <span>{statusText['castingInvited']}</span>
          </Div>
        );
      }

      if (applicant.status === 'shortlisted') {
        return (
          <Div flex className='align-center'>
            <SolutionOutlined />
            <span>{statusText['shortlisted']}</span>
          </Div>
        );
      }

      return (
        <Div>
          <span>{statusText['none']}</span>
        </Div>
      );
    };

    const hasSocialLinks =
      applicant.instaFollowers || applicant.tiktokFollowers;

    return (
      <Div className='applicant-card' ref={ref}>
        <Div flex className='header'>
          <Div className='heading'>
            <Div className='title'>{applicant.name}</Div>
            <Div className='subtitle'>
              {new Date().getFullYear() - new Date(applicant.dob).getFullYear()}
              ,{' '}
              {applicant.roles?.map(
                (role, index) => `${index !== 0 ? ', ' : ''}${role}`
              )}
            </Div>
          </Div>
          <Div flex className='applicant-status'>
            {renderApplicantStatus()}
          </Div>
        </Div>
        <Div flex className='profile'>
          <Div flex className='left-column'>
            <Div className='photo'>
              <img src={applicant.profilePhotoThumbnail} />
            </Div>
            <Div className='body-figure' pv='xs'>
              <Text>
                {applicant.height}
                <span className='unit'>CM</span> {applicant.weight}
                <span className='unit'>KG</span>
              </Text>
              <Text>
                {applicant.chestSize}&quot; {applicant.waistSize}&quot;{' '}
                {applicant.hipSize}&quot;
              </Text>
            </Div>
          </Div>
          <Div className='info'>
            <Div className='summary'>
              {applicant.isRecommended ? (
                <span className='recommend-tag'>Recommend</span>
              ) : null}
              <Text>
                Applied {moment(new Date(applicant.appliedAt)).fromNow()}
              </Text>
              {hasSocialLinks ? (
                <>
                  <Div flex className='stat'>
                    {applicant.instaFollowers && (
                      <Div flex className='social-media-container'>
                        <InstagramOutlined
                          style={{
                            fontSize: '1.5rem',
                            color: '#E1306C',
                            paddingRight: '4px',
                          }}
                        />
                        <Text>
                          {formatNumber(Number(applicant.instaFollowers))}
                        </Text>
                      </Div>
                    )}
                    {applicant.tiktokFollowers && (
                      <Div flex className='social-media-container'>
                        <Icon
                          component={() => <TikTokSvg size={25} />}
                          style={{
                            paddingRight: '4px',
                          }}
                        />
                        <Text>{applicant.tiktokFollowers}</Text>
                      </Div>
                    )}
                  </Div>
                </>
              ) : null}

              {applicant.statusUpdatedAt ? (
                <Text>
                  Status updated{' '}
                  {moment(new Date(applicant.statusUpdatedAt)).fromNow()}
                </Text>
              ) : null}
            </Div>
            <Div flex className='gallery'>
              {applicant.gallery
                ?.slice(0, maxGalleryItemDisplay)
                ?.map((item, index) => (
                  <a
                    key={index}
                    onClick={() => openGallery(index)}
                    className='image-container'
                  >
                    <img src={item.thumbnail} />
                  </a>
                ))}
              {applicant.gallery?.length > maxGalleryItemDisplay && (
                <a
                  onClick={() => openGallery(maxGalleryItemDisplay)}
                  className='image-container'
                >
                  <img
                    src={applicant.gallery[maxGalleryItemDisplay].thumbnail}
                  />
                  <Text className='show-more'>
                    +{applicant.gallery.length - maxGalleryItemDisplay}
                  </Text>
                </a>
              )}
            </Div>
            <Div className='experiences'>
              {applicant.experiences?.length
                ? [...applicant.experiences]
                    ?.sort((a, b) => {
                      if (b.year === a.year && b.createdAt && a.createdAt)
                        return b.createdAt?.localeCompare(a.createdAt);

                      return b.year - a.year;
                    })
                    .slice(0, maxExperiencesDisplay)
                    .map((experience, index) => (
                      <Div className='experience' key={index}>
                        {experience.year}, {experience.role}, {experience.type}
                        {experience.link && (
                          <>
                            <br />
                            <AntLink href={experience.link} target='_blank'>
                              <PlayCircleOutlined /> {experience.name}
                            </AntLink>
                          </>
                        )}
                      </Div>
                    ))
                : 'No previous experiences'}
            </Div>
            {invitation?.castingType === 'self' && (
              <Div className='self-casting-video'>
                <Div>Self Casting Video</Div>
                <a
                  onClick={() =>
                    openGallery(
                      0,
                      [
                        {
                          original: invitation?.selfCastingVideo ?? '',
                          thumbnail: videoThumbnailPlaceholder,
                          type: 'video',
                        },
                      ],
                      false
                    )
                  }
                  className='image-container'
                >
                  {invitation?.selfCastingVideo ? (
                    <img src={videoThumbnailPlaceholder} />
                  ) : (
                    <span style={{ color: '#B5B5B5', fontStyle: 'italic' }}>
                      Pending submission
                    </span>
                  )}
                </a>
              </Div>
            )}
          </Div>
        </Div>
        {ratingStats && ratingStats.totalRatingCount > 0 && (
          <Div className='rating'>
            <Div flex className='rating-item'>
              <Div flex className='items-center'>
                <span className='bold'>Will Hire Again</span>
                <Tooltip
                  title={'Percentage of hirers who will hire this talent again'}
                >
                  <InfoCircleOutlined className='info-icon' />
                </Tooltip>
              </Div>
              <Div className='bold text-right'>
                {ratingStats.hireAgain * 100}%
              </Div>
            </Div>
            <Div flex className='rating-item'>
              <Div flex className='items-center'>
                <span className='bold'>Overall Performance</span>
                <Tooltip title="Evaluate the talent's overall performance in the project.">
                  <InfoCircleOutlined className='info-icon' />
                </Tooltip>
              </Div>

              <Rate
                count={Math.round(ratingStats.overallPerformance)}
                defaultValue={Math.round(ratingStats.overallPerformance)}
                disabled
                className='text-black'
              />
            </Div>
            <Link
              target='_blank'
              to={`/t/${applicant.talentId}`}
              className='btn-view-details'
            >
              View Details
            </Link>
          </Div>
        )}
        <ApplicantCardAction
          withdraw={withdraw}
          invitation={invitation}
          handleClickCallback={handleClickCallback}
          addApplicantViewCount={addApplicantViewCount}
          applicant={applicant}
          role={role}
          project={project}
        />
      </Div>
    );
  }
);

ApplicantCard.displayName = 'ApplicantCard';
export default ApplicantCard;
