import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Typography, Tag } from 'antd';
import { format, parseISO } from 'date-fns';
import { IdcardOutlined, MoreOutlined, StarOutlined } from '@ant-design/icons';
import ArticleSvg from '../../../framework/Icon/ArticleSvg';
import MoneySvg from '../../../framework/Icon/MoneySvg';
import ShareProjectDrawer from '../../Drawer/ShareProjectDrawer/ShareProjectDrawer';
import ProjectActionDropdown from '../../Dropdown/ProjectActionDropdown';
import { Button, Div } from '../../../framework';
import {
  Project,
  RequestPaymentInvoice,
  RequestPaymentInvoiceStatus,
} from '../../../helper/type';
import { RootState } from '../../../store/store';
import { featureToggle } from '../../../../config/app.config';

import './ProjectCard.scss';

const { Paragraph, Text } = Typography;

type Props = {
  project: Project;
  type?: 'talent' | 'hirer';
  isUnpaid?: boolean;
};

const ProjectCard: FC<Props> = ({ project, type = 'hirer', isUnpaid }) => {
  const { mappedRequestPaymentInvoice } = useSelector(
    (state: RootState) => state.hirer
  );
  const [shouldShowDrawer, setShouldShowDrawer] = useState(false);
  const [isPastProject] = useState(
    project.closingDate < new Date().toISOString()
  );
  const projectInvoice: RequestPaymentInvoice | undefined =
    mappedRequestPaymentInvoice[project?.id];

  const handleShare = async () => {
    if (!isPastProject) {
      setShouldShowDrawer(true);
    }
  };

  const renderShootingDate = (project: Project) => {
    if (project.isNotConsecutiveDate) {
      return (
        <>
          {project.shootDates && project.shootDates.length > 0
            ? project.shootDates.map((date) => {
                return (
                  <Div key={date}>{format(parseISO(date), 'dd MMM yyy')}</Div>
                );
              })
            : 'TBC'}
        </>
      );
    }

    return (
      <>
        {project.shootDate ? (
          <>
            <Div>
              <small>FROM</small>
              {format(new Date(project.shootDate.from), 'dd MMM yyyy')}
            </Div>
            <Div>
              <small>TO</small>
              {format(new Date(project.shootDate.to), 'dd MMM yyyy')}
            </Div>
          </>
        ) : (
          'TBC'
        )}
      </>
    );
  };
  const renderActionButtons = () => {
    if (!isPastProject) {
      return (
        <Div className='action' flex>
          <Link
            className='action-button'
            to={
              type === 'hirer'
                ? `/hirer/project/${project.id}`
                : `/p/${project.id}`
            }
          >
            <IdcardOutlined />
            <Text>View Details</Text>
          </Link>
          <ProjectActionDropdown project={project} handleShare={handleShare}>
            <button className='action-button'>
              <MoreOutlined />
              <Text>More Action</Text>
            </button>
          </ProjectActionDropdown>
        </Div>
      );
    }

    if (featureToggle.invoice && isUnpaid) {
      return (
        <Div className='action' flex>
          <Button
            ghost
            className='unpaid-action-button'
            target='_blank'
            to={`/hirer/project/${project.id}/invoice/${projectInvoice?.id}`}
            disabled={!projectInvoice}
          >
            <span className='action-button-icon'>
              <ArticleSvg />
            </span>
            <Text>View Invoice</Text>
          </Button>
          <Button
            ghost
            className='unpaid-action-button'
            onClick={() => {
              if (projectInvoice?.paymentGatewayInvoiceUrl)
                window.location.href = projectInvoice?.paymentGatewayInvoiceUrl;
            }}
            disabled={
              projectInvoice?.status !==
              RequestPaymentInvoiceStatus.PENDING_PAYMENT_GATEWAY_WEBHOOK
            }
          >
            <span className='action-button-icon'>
              <MoneySvg />
            </span>
            <Text>Make Payment</Text>
          </Button>
        </Div>
      );
    }

    if (
      (featureToggle.invoice && projectInvoice) ||
      (featureToggle.rating && isPastProject)
    ) {
      return (
        <Div className='action' flex>
          {featureToggle.invoice && projectInvoice && (
            <Button
              ghost
              className='unpaid-action-button'
              target='_blank'
              to={`/hirer/project/${project.id}/invoice/${projectInvoice?.id}`}
            >
              <span className='action-button-icon'>
                <ArticleSvg />
              </span>
              <Text>View Invoice</Text>
            </Button>
          )}
          {featureToggle.rating && isPastProject && (
            <Link
              className='action-button'
              to={`/hirer/project/${project.id}/ratings`}
            >
              <StarOutlined />
              <Text>View Ratings</Text>
            </Link>
          )}
        </Div>
      );
    }
  };

  return (
    <Div className='project-card'>
      <Div flex className='header'>
        <Div>
          <Text className='title'>{project.title}</Text>
          <Text className='subtitle'>{project.media?.join(', ')}</Text>
        </Div>
      </Div>

      {type === 'hirer' && (
        <Div flex className='summary'>
          <Div className='attribute'>
            <Text className='value'>{project.availableRoles ?? '-'}</Text>
            <Text className='label'>Available Roles</Text>
          </Div>
          <Div className='attribute'>
            <Text className='value'>{project.totalApplicants ?? '-'}</Text>
            <Text className='label'>Total Applicants</Text>
          </Div>
          <Div className='attribute'>
            <Text className='value'>
              {project.isActive === 'active' ? (
                <Tag color='#389e0d' style={{ margin: 0 }}>
                  Published
                </Tag>
              ) : (
                <Tag color='gray' style={{ margin: 0 }}>
                  Unpublished
                </Tag>
              )}
            </Text>
            <Text className='label'>Status</Text>
          </Div>
          {/* <Div className='attribute'>
            <Text className='value'>8</Text>
            <Text className='label'>Pending Action</Text>
          </Div> */}
        </Div>
      )}
      <Div className='description'>
        {project.description &&
          project.description.split('\n').map((d, index) => (
            <Paragraph key={`${index}${d}`} style={{ marginBottom: '0.25rem' }}>
              {d}
            </Paragraph>
          ))}
      </Div>
      <Div className='details'>
        <Div flex className='attribute'>
          <Div className='label'>SHOOT LOCATION</Div>
          <Div className='value'>{project.shootLocation}</Div>
        </Div>
        <Div flex className='attribute'>
          <Div className='label date'>SHOOT DATE</Div>
          <Div className='value'>{renderShootingDate(project)}</Div>
        </Div>
        <Div flex className='attribute'>
          <Div className='label'>CLOSING DATE</Div>
          <Div className='value'>
            {format(new Date(project.closingDate), 'dd MMM yyyy')}
          </Div>
        </Div>
      </Div>

      {renderActionButtons()}

      <ShareProjectDrawer
        show={shouldShowDrawer}
        project={project}
        onClose={() => setShouldShowDrawer(false)}
      />
    </Div>
  );
};

export default ProjectCard;
