import React, {
  FC,
  ForwardRefRenderFunction,
  forwardRef,
  memo,
  useImperativeHandle,
  useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Div, DivProps } from '../../framework/Div/Div';
import { openModal } from '../../store/app.slice';
import PhotoManager, {
  Photo,
  PhotoManagerHandle,
} from '../PhotoManager/PhotoManager';

import './AvatarUpload.scss';

type Props = Omit<DivProps, 'onChange'> & {
  onChange?: (value: Photo[]) => void;
  value?: { original: string; thumbnail: string };
  removable?: boolean;
  required?: boolean;
  maxFileSizeInKb?: number;
  scope: string;
  directory: string;
};

export type AvatarUploadHandle = {
  update: () => Promise<Photo[]> | undefined;
  reset: () => void | undefined;
  hasChanged: () => boolean | undefined;
};

const AvatarUpload: ForwardRefRenderFunction<AvatarUploadHandle, Props> = (
  {
    onChange,
    value,
    removable,
    required,
    maxFileSizeInKb = 1 * 1024,
    scope,
    directory,
    ...props
  },
  ref
) => {
  const dispatch = useDispatch();
  const photoManagerRef = useRef<PhotoManagerHandle>(null);

  const updateHandler = () => photoManagerRef.current?.update();
  const resetHandler = () => photoManagerRef.current?.reset();
  const checkChangedHandler = () => photoManagerRef.current?.hasChanged();
  useImperativeHandle(
    ref,
    () => ({
      update: updateHandler,
      reset: resetHandler,
      hasChanged: checkChangedHandler,
    }),
    [updateHandler, resetHandler, checkChangedHandler]
  );

  const openGallery = (original: string, thumbnail: string) => {
    dispatch(
      openModal({
        modal: 'media-gallery',
        config: {
          context: {
            gallery: [{ original, thumbnail }],
            index: 0,
          },
        },
      })
    );
  };

  return (
    <Div className='avatar-upload' mb='s' {...props}>
      <Div flex>
        <Div mr='xl' noGrow>
          <PhotoManager
            ref={photoManagerRef}
            scope={scope}
            directory={directory}
            value={value ? [value] : undefined}
            cropAspect={1}
            required={required}
            maxFileSizeInKb={maxFileSizeInKb}
            fileSizeThresholdToCompressInKb={maxFileSizeInKb}
            onChange={onChange}
            renderPreview={({ originalUrl, thumbnailUrl }) => (
              <Div flex>
                <Div className='avatar-preview' noGrow mr='l'>
                  <img
                    src={thumbnailUrl}
                    onClick={() => openGallery(originalUrl, thumbnailUrl)}
                  />
                </Div>
                <AvatarUploadInfo
                  label='Change Photo'
                  maxFileSizeInKb={maxFileSizeInKb}
                  removable={removable}
                  changeHandler={photoManagerRef.current?.openFileDialog}
                />
              </Div>
            )}
            renderDropzone={() => (
              <Div flex>
                <Div className='upload-avatar-button' noGrow mr='l'>
                  <UserOutlined className='icon' />
                </Div>
                <AvatarUploadInfo
                  maxFileSizeInKb={maxFileSizeInKb}
                  label='Click to Upload'
                />
              </Div>
            )}
          />
        </Div>
      </Div>
    </Div>
  );
};

const AvatarUploadInfo: FC<{
  label: string;
  maxFileSizeInKb: number;
  removable?: boolean;
  changeHandler?: () => void;
  removeHandler?: () => void;
}> = ({
  label,
  maxFileSizeInKb,
  removable = false,
  changeHandler,
  removeHandler,
}) => (
  <Div>
    <Div onClick={changeHandler} className='cursor-pointer'>
      <span className='bold' style={{ color: '#574DD3' }}>
        {label}
      </span>
      <br />
      <Div>
        Supported formats: JPG, PNG
        <br />
        File size limit: {maxFileSizeInKb / 1024}MB
      </Div>
    </Div>
    {removable && (
      <Button
        type='link'
        className='remove-avatar-button'
        onClick={removeHandler}
      >
        Remove
      </Button>
    )}
  </Div>
);

export default memo(forwardRef(AvatarUpload));
