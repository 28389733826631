import React, { FunctionComponent } from 'react';
import { createPortal } from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'antd';
import { RootState } from '../../store/store';
import { closeModal } from '../../store/app.slice';

const modalRoot =
  typeof window !== 'undefined' && document.getElementById('modal');

interface Props {
  modals: Record<string, FunctionComponent>;
}

const GlobalModal: FunctionComponent<Props> = ({ modals }) => {
  const dispatch = useDispatch();
  const { modal } = useSelector((state: RootState) => state.app);
  const ModalContent = modals[modal.name as string];

  return modalRoot
    ? createPortal(
        <Modal
          open={Boolean(modal.name)}
          destroyOnClose
          onCancel={() => dispatch(closeModal())}
          width={1000}
          footer={[]}
          closable={false}
          {...modal.config?.modalProps}
        >
          <ModalContent />
        </Modal>,
        modalRoot
      )
    : null;
};

export default GlobalModal;
