import React, { FC, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Typography } from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  HeartOutlined,
  IdcardOutlined,
  MoreOutlined,
  SolutionOutlined,
  StopOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { Div } from '../../../framework';
import { Activity, Applicant, Project, Role } from '../../../helper/type';
import { openModal } from '../../../store/app.slice';
import { UpdateApplicantStatusModalContext } from '../../Modal/UpdateApplicantStatusModal';
import { setApplicantRecommend } from '../../../store/hirer.slice';
import { useJobService } from '../../../service/job.service';
import { RootState } from '../../../store/store';
import { CancelCastingModalContext } from '../../Modal/CancelCastingModal';
import { useApplicantStatus } from '../../../hook/useApplicantStatus';

import './ApplicantCardAction.scss';

const { Text } = Typography;

type Props = {
  applicant: Applicant;
  project?: Partial<Project>;
  role?: Partial<Role>;
  withdraw?: Activity;
  invitation?: Activity;
  addApplicantViewCount: (type: string) => void;
  handleClickCallback?: () => void;
};

const ApplicantCardAction: FC<Props> = ({
  applicant,
  project,
  role,
  withdraw,
  invitation,
  addApplicantViewCount,
  handleClickCallback,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const jobService = useJobService();
  const { authHirer, impersonator } = useSelector(
    (state: RootState) => state.user
  );
  const { mappedActivities } = useSelector((state: RootState) => state.hirer);
  const { getApplicantStatus } = useApplicantStatus('hirer');

  const [applicantStatusKey, setApplicantStatusKey] = useState<string>('');

  const { impersonateId, impersonateKey } = impersonator;
  const isImpersonator = impersonateId && impersonateKey;

  const { title: projectTitle = '', id: projectId } = project || {};

  useEffect(() => {
    if (mappedActivities[applicant.roleId]?.[applicant.talentId]) {
      setApplicantStatusKey(
        getApplicantStatus({
          roleId: applicant.roleId,
          talentId: applicant.talentId,
        }) ?? ''
      );
    }
  }, [mappedActivities]);

  const openInvitationModal = (mode: 'new' | 'edit') => {
    dispatch(
      openModal({
        modal: 'invite-casting',
        config: {
          context: {
            mode,
            multipleInvite: false,
            roleId: applicant.roleId,
            hirerId: authHirer.hirerId,
            projectId,
            projectTitle,
            roleName: role?.name,
            applicant,
            invitation: mode === 'edit' ? invitation : undefined,
          },
        },
      })
    );
  };

  const openCancelCastingModal = () => {
    if (!invitation) return;

    const context: CancelCastingModalContext = {
      applicant,
      invitation,
    };
    dispatch(openModal({ modal: 'cancel-casting', config: { context } }));
  };

  const openStatusModal = (status: 'shortlisted' | 'confirmed') => {
    const context: UpdateApplicantStatusModalContext = {
      status,
      talentId: applicant.talentId,
      roleId: applicant.roleId,
      applicationId: applicant.id,
      hirerId: authHirer.hirerId,
      talentName: applicant.name,
      projectId,
      projectTitle,
      roleName: role?.name ?? '',
      talentPhoneNumber: applicant.phoneNumber,
      talentPhoneNumberPrefix: applicant.phoneNumberPrefix,
      scheduledDatetime: (role?.shootDates ?? [''])[0],
    };
    dispatch(
      openModal({
        modal: 'update-applicant-status',
        config: { context },
      })
    );
  };

  const handleViewProfile = () => {
    addApplicantViewCount('profile');

    if (handleClickCallback) {
      handleClickCallback();
    }

    history.push(`/t/${applicant.talentId}`, {
      fromApplicantList: true,
      openModalPayload: {
        modal: 'update-applicant-status',
        config: {
          context: {
            status: applicant.status,
            talentId: applicant.talentId,
            roleId: applicant.roleId,
          },
        },
      },
    });
  };

  const handleUpdateRecommend = async (isRecommended: boolean) => {
    const payload = {
      roleId: applicant.roleId,
      talentId: applicant.talentId,
      isRecommended,
    };
    await jobService.updateApplicantRecommend(payload);
    dispatch(setApplicantRecommend(payload));
  };

  const actionButtons = {
    viewProfile: () => ({
      label: (
        <Text>
          <Link to={`/t/${applicant.talentId}`} className='btn-view-profile'>
            View Profile
          </Link>
        </Text>
      ),
      icon: <IdcardOutlined />,
      onClick: handleViewProfile,
      key: 'view',
      className: 'action-button',
    }),
    confirmApplicant: () => ({
      label: (
        <Text>
          {applicant.status === 'confirmed'
            ? 'Applicant Confirmed'
            : 'Confirm Applicant'}
        </Text>
      ),
      icon: <CheckOutlined />,
      onClick: () => {
        handleClickCallback && handleClickCallback();
        openStatusModal('confirmed');
      },
      key: 'confirm',
      className: 'action-button',
      disabled: applicant.status === 'confirmed',
    }),
    inviteCasting: () => ({
      label: <Text>Invite Casting</Text>,
      icon: <VideoCameraOutlined />,
      onClick: () => openInvitationModal('new'),
      key: 'invite',
      className: 'action-button',
    }),
    editCastingInvitation: () => ({
      label: <Text>Edit Casting Invitation</Text>,
      icon: <EditOutlined />,
      onClick: () => openInvitationModal('edit'),
      key: 'edit-casting',
      className: 'action-button',
    }),
    cancelCasting: () => ({
      label: <Text>Cancel Casting Invitation</Text>,
      icon: <StopOutlined />,
      onClick: () => openCancelCastingModal(),
      key: 'cancel-casting',
      className: 'action-button',
    }),
    shortlist: () => ({
      label: <Text>Shortlist</Text>,
      icon: <SolutionOutlined />,
      onClick: () => {
        handleClickCallback && handleClickCallback();
        openStatusModal('shortlisted');
      },
      key: 'shortlist',
      className: 'action-button',
    }),
    recommend: () =>
      applicant.isRecommended
        ? {
            label: <Text>Remove Recommend</Text>,
            icon: <CloseOutlined />,
            onClick: () => handleUpdateRecommend(false),
            key: 'edit',
            className: 'action-button',
          }
        : {
            label: <Text>Recommend</Text>,
            icon: <HeartOutlined />,
            onClick: () => handleUpdateRecommend(true),
            key: 'edit',
            className: 'action-button',
          },
  };

  const getDropdownButtons = (keys: (keyof typeof actionButtons)[]) => {
    const buttons = keys.map((k) => actionButtons[k]());

    if (isImpersonator) buttons.push(actionButtons['recommend']());
    return buttons;
  };

  const renderButtons = () => {
    if (
      [
        'pendingConfirmation',
        'confirmationAccepted',
        'confirmationRejected',
      ].includes(applicantStatusKey)
    ) {
      return {
        cta: actionButtons['viewProfile'](),
        dropdown: getDropdownButtons(['confirmApplicant']),
      };
    }

    if (['castingInvited', 'castingScheduled'].includes(applicantStatusKey)) {
      const dropdown: (keyof typeof actionButtons)[] = [
        'viewProfile',
        'editCastingInvitation',
      ];

      const allowShortlist = ![
        'pendingConfirmation',
        'confirmationAccepted',
        'confirmationRejected',
        'shortlisted',
      ].includes(applicantStatusKey);
      allowShortlist && dropdown.push('shortlist');

      const today = new Date().toISOString();
      const { scheduledDatetime, castingType } = invitation ?? {};
      const allowCancel = !scheduledDatetime || scheduledDatetime > today;
      if (castingType === 'self' || allowCancel) dropdown.push('cancelCasting');

      return {
        cta: actionButtons['confirmApplicant'](),
        dropdown: getDropdownButtons(dropdown),
      };
    }

    if (applicantStatusKey === 'shortlisted') {
      return {
        cta: actionButtons['inviteCasting'](),
        dropdown: getDropdownButtons(['viewProfile', 'confirmApplicant']),
      };
    }

    return {
      cta: actionButtons['shortlist'](),
      dropdown: getDropdownButtons([
        'viewProfile',
        'confirmApplicant',
        'inviteCasting',
      ]),
    };
  };

  const { cta, dropdown } = renderButtons();

  return (
    <Div className='action' flex>
      {withdraw ? (
        <Div p='m'>
          Withdrawal Reason:
          <br />
          {withdraw.withdrawReason}
        </Div>
      ) : (
        <>
          <button
            onClick={(e) => {
              e.preventDefault();
              cta && cta.onClick();
            }}
          >
            {cta.icon}
            {cta.label}
          </button>
          <Dropdown
            overlayClassName={'applicant-action-dropdown'}
            menu={{ items: dropdown }}
            trigger={['click']}
          >
            <button className='action-button'>
              <MoreOutlined />
              <Text>More Action</Text>
            </button>
          </Dropdown>
        </>
      )}
    </Div>
  );
};

export default ApplicantCardAction;
