import React, { FunctionComponent } from 'react';
import { Badge } from 'antd';
import {
  CalendarOutlined,
  FileSearchOutlined,
  InboxOutlined,
  ScheduleOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { useLocation, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Div } from '../../framework/Div/Div';
import { TALENT_ROUTES } from '../../route/constant';
import { HIRER_ROUTES } from '../../route/constant';
import { RootState } from '../../store/store';

import './BottomNavigation.scss';

const talentNavItems = [
  {
    path: TALENT_ROUTES.AGENDA,
    render: ({ pendingCount }: any) => (
      <Badge count={pendingCount > 0 ? '!' : undefined}>
        <ScheduleOutlined /> Agenda
      </Badge>
    ),
  },
  {
    path: '/talent/jobs',
    render: () => (
      <>
        <FileSearchOutlined /> Jobs
      </>
    ),
  },
  {
    path: '/talent/applications',
    render: () => (
      <>
        <InboxOutlined /> Applications
      </>
    ),
  },
  {
    path: '/talent/profile',
    render: () => (
      <>
        <UserOutlined /> Profile
      </>
    ),
  },
];

const hirerNavItems = [
  {
    path: HIRER_ROUTES.AGENDA,
    render: () => (
      <>
        <ScheduleOutlined /> Agenda
      </>
    ),
  },
  {
    path: HIRER_ROUTES.DASHBOARD,
    render: () => (
      <>
        <VideoCameraOutlined /> Projects
      </>
    ),
  },
  {
    path: HIRER_ROUTES.SCHEDULE_LISTING,
    render: () => (
      <>
        <CalendarOutlined /> Schedules
      </>
    ),
  },
];

type Props = {
  type?: 'hirer' | 'talent';
};

export const BottomNavigation: FunctionComponent<Props> = ({
  type = 'talent',
}) => {
  const { activities } = useSelector((state: RootState) => state.user);
  const { pathname } = useLocation();
  const navItems = type === 'talent' ? talentNavItems : hirerNavItems;

  return (
    <>
      <div className='bottom-nav'>
        <Div flex ph='s' pv='m' style={{ justifyContent: 'space-around' }}>
          {navItems.map((item) => (
            <Link
              key={item.path}
              className={`bottom-nav-item ${
                pathname.includes(item.path) ? '--active' : ''
              }`.trim()}
              to={item.path}
            >
              {item.render({ pendingCount: activities.pending.length })}
            </Link>
          ))}
        </Div>
      </div>
    </>
  );
};
