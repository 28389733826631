import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createPortal } from 'react-dom';
import { Link } from 'react-router-dom';
import { Button, Modal, Typography } from 'antd';
import { StarOutlined, UserOutlined } from '@ant-design/icons';
import { format } from 'date-fns';
import { Div } from '../../../framework';
import { Applicant, Project, Role } from '../../../helper/type';
import { useJobService } from '../../../service/job.service';
import { RootState } from '../../../store/store';

import './HirerToRateCard.scss';

const { Text } = Typography;
const modalRoot =
  typeof window !== 'undefined' && document.getElementById('modal');

type Props = {
  projectId: string;
  roleId: string;
  talentId: string;
  header?: boolean;
  action?: boolean;
  noStyle?: boolean;
};

const HirerToRateCard: FC<Props> = ({
  projectId,
  roleId,
  talentId,
  header,
  action,
  noStyle,
}) => {
  const { mappedRoles, mappedProjects, mappedApplicants } = useSelector(
    (state: RootState) => state.hirer
  );
  const jobService = useJobService();

  const [project, setProject] = useState<Project>();
  const [role, setRole] = useState<Role>();
  const [applicant, setApplicant] = useState<Applicant>();
  const [viewProfile, setViewProfile] = useState(false);

  useEffect(() => {
    if (!mappedProjects[projectId]) fetchProject();
    else setProject(mappedProjects[projectId]);

    if (!mappedRoles[projectId] || !mappedRoles[projectId][roleId]) fetchRole();
    else setRole(mappedRoles[projectId][roleId]);

    if (
      !mappedApplicants[roleId] ||
      mappedApplicants[roleId].applicants.find(
        (appl) => appl.talentId === talentId
      ) == null
    )
      fetchApplicant();
    else
      setApplicant(
        mappedApplicants[roleId].applicants.find(
          (appl) => appl.talentId === talentId
        )
      );
  }, []);

  const fetchProject = async () => {
    const proj = await jobService.getProject(projectId);
    setProject(proj);
  };
  const fetchRole = async () => {
    const role = await jobService.getRole(roleId);
    setRole(role);
  };
  const fetchApplicant = async () => {
    const appl = await jobService.getApplicant(roleId, talentId);
    setApplicant(appl);
  };

  return (
    <Div className={`hirer-to-rate-card ${noStyle ? 'no-style' : ''}`}>
      {header && (
        <Div flex className='header'>
          <Div className='title'>{project?.title}</Div>
        </Div>
      )}
      <Div flex className='body'>
        <Div flex className='left-column'>
          <Div className='photo'>
            <img src={applicant?.profilePhotoThumbnail} />
          </Div>
        </Div>
        <Div className='right-column' flex>
          <Div className='summary'>
            <Div className='applicant-name'>{applicant?.name}</Div>
            <Div className='role-title'>
              {role?.name} <span className='capitalize'>({role?.type})</span>
            </Div>
            <Div className='shoot-dates'>
              Shoot date:{' '}
              {role?.shootDates
                ?.map((date) => format(new Date(date), 'dd MMM yyyy'))
                .join(', ')}
            </Div>
          </Div>
        </Div>
      </Div>
      {action && (
        <Div className='action' flex>
          <Link
            to={`/hirer/rate/${projectId}/${roleId}?talentId=${talentId}`}
            className='action-button'
          >
            <StarOutlined />
            <Text>Rate Talent</Text>
          </Link>
          {applicant && (
            <Button
              type='link'
              className='action-button'
              onClick={() => setViewProfile(true)}
            >
              <UserOutlined />
              <Text>View Profile</Text>
            </Button>
          )}
        </Div>
      )}

      {modalRoot &&
        viewProfile &&
        createPortal(
          <Modal
            open
            destroyOnClose
            onCancel={() => setViewProfile(false)}
            width={1000}
            footer={null}
            closable
            centered
            wrapClassName='modal-view-talent-profile'
          >
            <iframe
              src={`/t/${talentId}?hidemenu=1&hideshare=1`}
              style={{ width: '100%', height: '90vh' }}
            />
          </Modal>,
          modalRoot
        )}
    </Div>
  );
};

export default HirerToRateCard;
