import { Drawer, Typography, message } from 'antd';
import Icon, {
  CopyOutlined,
  FacebookFilled,
  TwitterOutlined,
} from '@ant-design/icons';
import React, { FC, useEffect, useState } from 'react';
import { Div } from '../../../framework';
import { isClient } from '../../../helper/detect';
import WhatsAppSvg from '../../../framework/Icon/WhatsAppSvg';
import { Project } from '../../../helper/type';

const { Title } = Typography;

type Props = {
  show: boolean;
  project: Project;
  onClose: () => void;
};

const ShareProjectDrawer: FC<Props> = ({ show, project, onClose }) => {
  const [showDrawer, setShowDrawer] = useState(false);

  useEffect(() => {
    setShowDrawer(show);
  }, [show]);

  const handleCopyLink = async (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const clipboardItem = new ClipboardItem({
      'text/plain': new Promise((resolve) =>
        resolve(
          new Blob([`${window.location.origin}/p/${project.id}?src=link`], {
            type: 'text/plain',
          })
        )
      ),
    });
    await navigator.clipboard.write([clipboardItem]);
    message.success({
      content: 'Link Copied!',
    });
    setShowDrawer(false);
  };

  return (
    <Drawer
      placement='bottom'
      closable={false}
      visible={showDrawer}
      onClose={onClose}
    >
      <Title level={4}>Share to</Title>
      <Div flex>
        <a
          className='share-icon'
          href={`whatsapp://send?text=Hey, check out this job, ${
            project.title
          } at ${isClient ? window.location.origin : ''}/p/${
            project.id
          }?src=wa`}
          data-action='share/whatsapp/share'
          target='_blank'
          rel='noreferrer'
        >
          <Icon component={WhatsAppSvg} />
          <div>WhatsApp</div>
        </a>
        <a
          className='share-icon'
          href={`https://www.facebook.com/sharer/sharer.php?u=${
            isClient ? window.location.origin : ''
          }/p/${project.id}?src=fb`}
          target='_blank'
          rel='noreferrer'
        >
          <FacebookFilled style={{ fontSize: '3.2rem', color: '#3b5998' }} />
          <div>Facebook</div>
        </a>
        <a
          className='share-icon'
          href={`https://twitter.com/share?url=${
            isClient ? window.location.origin : ''
          }/p/${project.id}?src=tw`}
        >
          <TwitterOutlined style={{ fontSize: '3.2rem', color: '#1da1f2' }} />
          <div>Twitter</div>
        </a>
        <a className='share-icon' onClick={handleCopyLink}>
          <CopyOutlined style={{ fontSize: '3rem', color: '#565656' }} />
          <div>Copy Link</div>
        </a>
      </Div>
    </Drawer>
  );
};

export default ShareProjectDrawer;
