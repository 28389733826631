import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Rate, Tag } from 'antd';
import { CheckCircleFilled, CloseCircleOutlined } from '@ant-design/icons';
import { format } from 'date-fns';
import { Div } from '../../../framework';
import { Applicant, Project, Rating, Role } from '../../../helper/type';
import { useJobService } from '../../../service/job.service';
import { RootState } from '../../../store/store';

import './HirerViewRatingCard.scss';

type Props = {
  rating: Rating;
  project: Project;
  role: Role;
};

const HirerViewRatingCard: FC<Props> = ({ rating, project, role }) => {
  const jobService = useJobService();
  const { mappedApplicants } = useSelector((state: RootState) => state.hirer);

  const [applicant, setApplicant] = useState<Applicant>();
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    (async () => {
      const currentApplicant =
        mappedApplicants[role.id] &&
        mappedApplicants[role.id].applicants.find(
          (appl) => appl.talentId === rating.talentId
        );
      if (!currentApplicant) {
        const appl = await jobService.getApplicant(role.id, rating.talentId);
        setApplicant(appl);
      } else {
        setApplicant(currentApplicant);
      }
    })();
  }, []);

  const renderUnpublishedRatingItem = () => {
    const items: React.ReactNode[] = [renderRatingItemMetrics()];

    if (showDetails && rating.writtenReview) {
      items.push(
        <Div mt='xl' mb='l' className='written-review'>
          {rating.writtenReview}
        </Div>
      );
    }

    if (
      rating.status === 'PENDING' &&
      (!rating.overallPerformance || showDetails)
    ) {
      items.push(
        <Div mt={rating.overallPerformance ? 'l' : undefined}>
          <Link
            to={`/hirer/rate/${project.id}/${role.id}?talentId=${rating.talentId}&mode=edit`}
            className='link-action'
          >
            Edit Rating
          </Link>
        </Div>
      );
    }

    return <Div className='rating-item-container'>{items}</Div>;
  };

  const renderPublishedRatingItem = () => {
    if (!rating.overallPerformance) return;

    const items: React.ReactNode[] = [renderRatingItemMetrics()];

    if (showDetails && rating.writtenReview) {
      items.push(
        <Div mt='xl' mb='l' className='written-review'>
          {rating.writtenReview}
        </Div>,
        <Link
          to={`/hirer/rating/talent/${rating.talentId}/role/${rating.roleId}/comments`}
          className='link-action'
        >
          View Comments
        </Link>
      );
    }

    return <Div className='rating-item-container'>{items}</Div>;
  };

  const renderRatingItemMetrics = () => {
    if (!rating.overallPerformance) return;

    return (
      <>
        <Div flex className='rating-item'>
          <span className='item-label'>Overall Performance</span>
          <Rate
            count={rating.overallPerformance}
            defaultValue={rating.overallPerformance}
            disabled
            className='rating-stars'
          />
        </Div>
        {!showDetails ? (
          <Button
            type='link'
            className='link-action p-0'
            onClick={() => setShowDetails(true)}
          >
            View Details
          </Button>
        ) : (
          <>
            <Div flex className='rating-item'>
              <span className='item-label'>Punctuality</span>
              <Rate
                count={rating.punctuality}
                defaultValue={rating.punctuality}
                disabled
                className='rating-stars'
              />
            </Div>
            <Div flex className='rating-item'>
              <span className='item-label'>Professionalism</span>
              <Rate
                count={rating.professionalism}
                defaultValue={rating.professionalism}
                disabled
                className='rating-stars'
              />
            </Div>
            <Div flex className='rating-item'>
              <span className='item-label'>Responsiveness</span>
              <Rate
                count={rating.responsiveness}
                defaultValue={rating.responsiveness}
                disabled
                className='rating-stars'
              />
            </Div>
            <Div flex className='rating-item'>
              <span className='item-label'>Dialogue Mastery</span>
              <Rate
                count={rating.dialogueMastery}
                defaultValue={rating.dialogueMastery}
                disabled
                className='rating-stars'
              />
            </Div>
            <Div flex className='rating-item'>
              <span className='item-label'>Profile Representation</span>
              <Rate
                count={rating.profileRepresentation}
                defaultValue={rating.profileRepresentation}
                disabled
                className='rating-stars'
              />
            </Div>
          </>
        )}
      </>
    );
  };

  return (
    <Div className='hirer-view-rating-card'>
      <Div flex className='header'>
        <Div className='title'>{applicant?.name}</Div>
      </Div>
      <Div flex className='talent-container'>
        <Div flex className='left-column'>
          <Div className='photo'>
            <img src={applicant?.profilePhotoThumbnail} />
          </Div>
        </Div>
        <Div flex className='right-column'>
          <Div className='summary'>
            <Div className='role-title'>
              {role?.name} <span className='capitalize'>({role?.type})</span>
            </Div>
            <Div className='shoot-dates'>
              Shoot date:{' '}
              {role?.shootDates
                ?.map((date) => format(new Date(date), 'dd MMM yyyy'))
                .join(', ')}
            </Div>
            <Div className='hire-again'>
              {rating.hireAgain ? (
                <>
                  <CheckCircleFilled className='text-success' /> Will hire again
                </>
              ) : (
                <>
                  <CloseCircleOutlined className='text-danger' /> Will not hire
                  again
                </>
              )}
            </Div>
          </Div>
        </Div>
      </Div>

      {rating.status === 'PUBLISHED'
        ? renderPublishedRatingItem()
        : renderUnpublishedRatingItem()}

      <Div className='other-details'>
        <Div flex mb='l'>
          <Div>
            <Div className='font-bold'>Rated By</Div>
            <Div className='text-black'>{rating.authorUserName}</Div>
          </Div>
          <Div>
            <Div className='font-bold'>Submitted On</Div>
            <Div className='text-black'>
              {format(new Date(rating.createdAt), 'dd MMM yyyy')}
            </Div>
          </Div>
        </Div>
        <Div flex>
          <Div>
            <Div className='font-bold'>Status</Div>
            {rating.status === 'PUBLISHED' ? (
              <Tag color='#16B55E'>Published</Tag>
            ) : (
              <Tag color='#444444'>Not Published</Tag>
            )}
          </Div>
        </Div>
      </Div>
    </Div>
  );
};

export default HirerViewRatingCard;
