import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import { MoreOutlined, TeamOutlined } from '@ant-design/icons';
import RoleActionDropdown from '../../Dropdown/RoleActionDropdown';
import { Div } from '../../../framework';
import { Role, Project } from '../../../helper/type';

import './RoleCard.scss';

const { Paragraph, Text } = Typography;

type Props = {
  role: Role;
  project: Project;
};

const RoleCard: FC<Props> = ({ role, project }) => {
  return (
    <Div className='role-card'>
      <Div flex className='role-header'>
        <Div>
          <Text className='title'>{role.name}</Text>
          <Text className='subtitle'>{role.type}</Text>
        </Div>
      </Div>
      <Div flex className='role-summary'>
        <Div className='attribute'>
          <Text className='value'>{role.totalApplicants ?? '-'}</Text>
          <Text className='label'>Total Applicants</Text>
        </Div>
        {/*<Div className='attribute'>
          <Text className='value'>8</Text>
          <Text className='label'>Pending Actions</Text>
        </Div>*/}
      </Div>
      <Div flex className='role-summary'>
        {/* <Div flex className='attribute'>
          <Text className='value'>30</Text>
          <Text className='label'>Total Applicants</Text>
        </Div> */}
        <Div className='description'>
          {role.description &&
            role.description.split('\n').map((d, index) => (
              <Paragraph
                key={`${index}${d}`}
                style={{ marginBottom: '0.25rem' }}
              >
                {d}
              </Paragraph>
            ))}
        </Div>
      </Div>
      <Div className='role-details'>
        <Div flex className='attribute'>
          <Div className='label'>GENDER</Div>
          <Div className='value capitalize'>{role.gender}</Div>
        </Div>
        <Div flex className='attribute'>
          <Div className='label'>AGE RANGE</Div>
          <Div className='value'>
            {role.ageFrom} - {role.ageTo} <small>YEARS OLD</small>
          </Div>
        </Div>
        <Div flex className='attribute'>
          <Div className='label'>RACE/ETHNICITY</Div>
          <Div className='value capitalize'>
            {role.raceEthnicity && role.raceEthnicity.join(', ')}
            {!role.raceEthnicity &&
              Boolean(role.race) &&
              Boolean(role.ethnicity) &&
              [role.race, role.ethnicity].join(', ')}
          </Div>
        </Div>
        <Div flex className='attribute'>
          <Div className='label'>LANGUAGE</Div>
          <Div className='value capitalize'>
            {role.languages && role.languages.length
              ? role.languages.join(', ')
              : 'Not required'}
          </Div>
        </Div>
        <Div flex className='attribute'>
          <Div className='label'>TALENTS NEEDED</Div>
          <Div className='value'>
            {role.countNeeded} <small>PERSON(S)</small>
          </Div>
        </Div>
      </Div>
      <Div className='role-action' flex>
        <Link to={`/hirer/project/${project.id}/role/${role.id}`}>
          <TeamOutlined />
          <Text>View Applicants</Text>
        </Link>
        <RoleActionDropdown project={project} role={role} allowSendInvitation>
          <button className='action-button'>
            <MoreOutlined />
            <Text>More Action</Text>
          </button>
        </RoleActionDropdown>
      </Div>
    </Div>
  );
};

export default RoleCard;
