import React, { FunctionComponent, useState } from 'react';
import { useSelector } from 'react-redux';
import { Select, Spin } from 'antd';
import {
  GeoSearchResult,
  useLocationService,
} from '../../service/location.service';
import { RootState } from '../../store/store';

type Props = {
  placeholder?: string;
  onSelectAddress: (value: GeoSearchResult) => void;
};

const AddressInput: FunctionComponent<Props> = ({
  onSelectAddress,
  ...props
}) => {
  const { loadStack } = useSelector((state: RootState) => state.app);
  const locationService = useLocationService();

  const [options, setOptions] = useState<GeoSearchResult[]>();
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout>();

  const isSearchingAddress = loadStack.includes('location/search/addr');

  return (
    <Select
      {...props}
      allowClear
      showSearch
      filterOption={false}
      onSearch={(value) => {
        if (searchTimeout) clearTimeout(searchTimeout);

        if (value) {
          const timeout = setTimeout(async () => {
            const result = await locationService.searchAddress(value);
            setOptions(result);
          }, 800);

          setOptions([]);
          setSearchTimeout(timeout);
        }
      }}
      notFoundContent={isSearchingAddress ? <Spin size='small' /> : null}
      options={options?.map((opt) => ({
        label: opt.address,
        value: `${opt.lng}#${opt.lat}`,
      }))}
      onChange={(value) => {
        const location = options?.find(
          (opt) => `${opt.lng}#${opt.lat}` === value
        );
        if (location) {
          onSelectAddress(location);
        }
      }}
    />
  );
};

export default AddressInput;
