import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import TagManager from 'react-gtm-module';
import routes from './route/app.route';
import GlobalLoader from './component/GlobalLoader/GlobalLoader';
import {
  setAuthUser,
  setIsTalentOnboarded,
  setPartialTalentProfile,
  setProfilePicture,
  setLastVerifyEmailResendTime,
  setLastForgotPassEmailResendTime,
  setAuthHirer,
} from './store/user.slice';
import {
  setIsPWA,
  setBrowserDetail,
  setPromptEvent,
  setIsSubscribeNotif,
  setCountRejectPromptInstall,
} from './store/app.slice';
import { setCompanyProfile, setInvitationInfo } from './store/hirer.slice';
import { Div } from './framework/Div/Div';
import GlobalModal from './component/GlobalModal/GlobalModal';
import modals from './component/Modal';
import { googleTrackingId } from '../config/app.config';
import { getBrowserDetail } from './helper/getBrowserDetail';
import { getSubscription } from './serviceWorkerRegistration';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const _user = localStorage.getItem('_user');
      if (_user) {
        dispatch(setAuthUser(JSON.parse(_user)));
      }

      const _isTalentOnboarded = localStorage.getItem('_isTalentOnboarded');
      if (_isTalentOnboarded) {
        dispatch(setIsTalentOnboarded(JSON.parse(_isTalentOnboarded)));
      }

      const _profilePictureUrl = localStorage.getItem('_profilePictureUrl');
      if (_profilePictureUrl) {
        dispatch(setProfilePicture(JSON.parse(_profilePictureUrl)));
      }

      const _talent = localStorage.getItem('_talent');
      if (_talent) {
        dispatch(setPartialTalentProfile(JSON.parse(_talent)));
      }

      const _lastVerifyEmailResendTime = localStorage.getItem(
        '_lastVerifyEmailResendTime'
      );
      if (_lastVerifyEmailResendTime) {
        dispatch(
          setLastVerifyEmailResendTime(JSON.parse(_lastVerifyEmailResendTime))
        );
      }

      const _lastForgotPassEmailResendTime = localStorage.getItem(
        '_lastForgotPassEmailResendTime'
      );
      if (_lastForgotPassEmailResendTime) {
        dispatch(
          setLastForgotPassEmailResendTime(
            JSON.parse(_lastForgotPassEmailResendTime)
          )
        );
      }

      const _countRejectPromptInstall = localStorage.getItem(
        '_countRejectPromptInstall'
      );
      if (_countRejectPromptInstall) {
        dispatch(
          setCountRejectPromptInstall({
            count: parseInt(_countRejectPromptInstall),
          })
        );
      }

      const _invitationInfo = localStorage.getItem('_invitationInfo');
      if (_invitationInfo) {
        dispatch(setInvitationInfo(JSON.parse(_invitationInfo)));
      }

      const _authHirer = localStorage.getItem('_authHirer');
      if (_authHirer) {
        dispatch(setAuthHirer(JSON.parse(_authHirer)));
      }

      const _companyProfile = localStorage.getItem('_companyProfile');
      if (_companyProfile) {
        dispatch(setCompanyProfile(JSON.parse(_companyProfile)));
      }
    })();
  }, []);

  useEffect(() => {
    if (googleTrackingId) {
      TagManager.initialize({
        gtmId: googleTrackingId,
      });
    }
  }, []);

  useEffect(() => {
    let displayMode = 'browser tab';
    if (window.matchMedia('(display-mode: standalone)').matches) {
      displayMode = 'standalone';

      dispatch(setIsPWA({ isPWA: true }));
    }
    console.log('DISPLAY_MODE_LAUNCH:', displayMode);

    window.addEventListener('beforeinstallprompt', (event) => {
      if (event) {
        event.preventDefault();
        console.log(event);
        dispatch(setPromptEvent(event));
      }
    });

    const browser = getBrowserDetail();

    dispatch(setBrowserDetail(browser));
  }, []);

  return (
    <Div className='app-wrapper'>
      <GlobalLoader />
      <GlobalModal modals={modals} />
      <Div>
        <Switch>
          {routes.map((props, key) => (
            <Route key={key} {...props} />
          ))}
        </Switch>
      </Div>
    </Div>
  );
}

export default App;
