import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Avatar, Button, Divider, Form, Select, Spin } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { Div } from '../../../framework';
import { Applicant } from '../../../helper/type';
import { RootState } from '../../../store/store';

const { Option } = Select;

export type PromptInviteOtherFormValues = {
  selectedApplicants: string[];
};

type Props = {
  mode: 'new' | 'edit';
  applicants: Applicant[];
  defaultSelected: string[];
  hasLoadOtherApplicants: boolean;
  onApplicantDropdownVisibleChange: (open: boolean) => Promise<void>;
  onLoadMoreApplicant?: () => Promise<Applicant[]>;
  onFinish: (values: PromptInviteOtherFormValues) => void;
  onCancel: () => void;
  error?: string;
};

const PromptInviteOther: FC<Props> = ({
  mode,
  applicants,
  defaultSelected,
  hasLoadOtherApplicants,
  onApplicantDropdownVisibleChange,
  onLoadMoreApplicant,
  onFinish,
  onCancel,
  error,
}) => {
  const [form] = Form.useForm();

  const { loadStack } = useSelector((state: RootState) => state.app);
  const isLoadingApplicant =
    loadStack.includes(`job/applicant/list?isRecommended=true`) ||
    loadStack.includes(`job/applicant/list?isRecommended=false`);

  useEffect(() => {
    form.setFieldValue('selectedApplicants', defaultSelected);
  }, [defaultSelected]);

  return (
    <Form form={form} onFinish={onFinish}>
      <Form.Item
        name='selectedApplicants'
        rules={[
          {
            required: true,
            message: 'Please select at least 1 applicant to invite.',
          },
        ]}
      >
        <Select
          showSearch
          mode='multiple'
          size='large'
          placeholder='Select applicants'
          onDropdownVisibleChange={onApplicantDropdownVisibleChange}
          dropdownRender={(menu) => (
            <>
              <Spin spinning={isLoadingApplicant}>{menu}</Spin>
              {!hasLoadOtherApplicants && (
                <>
                  <Divider style={{ margin: 0 }} />
                  <Div p='xs'>
                    <Button
                      type='link'
                      onClick={onLoadMoreApplicant}
                      disabled={isLoadingApplicant}
                    >
                      <CaretDownOutlined style={{ marginRight: '0.5rem' }} />
                      Load Other Applicants
                    </Button>
                  </Div>
                </>
              )}
            </>
          )}
        >
          {applicants.map((applicant) => (
            <Option key={applicant.talentId} value={applicant.talentId}>
              <Div flex style={{ alignItems: 'center' }}>
                <Avatar src={applicant.profilePhotoThumbnail} />
                <span style={{ marginLeft: '0.5rem' }}>{applicant.name}</span>
              </Div>
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Div style={{ color: 'red' }}>{error}</Div>
      {error ? (
        <Form.Item>
          <Button block onClick={onCancel}>
            Dismiss
          </Button>
        </Form.Item>
      ) : (
        <Form.Item>
          <Button
            block
            type='primary'
            htmlType='submit'
            style={{ marginBottom: '1rem' }}
          >
            {mode === 'edit' ? 'Modify All' : 'Invite all'}
          </Button>
          <Button block onClick={onCancel}>
            No
          </Button>
        </Form.Item>
      )}
    </Form>
  );
};

export default PromptInviteOther;
