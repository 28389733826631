import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Typography, Dropdown } from 'antd';
import { EditOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Role, Project } from '../../helper/type';
import { openModal } from '../../store/app.slice';

import './RoleActionDropdown.scss';

const { Text } = Typography;

type Props = {
  project: Partial<Project>;
  role: Role;
  allowSendInvitation?: boolean;
  className?: string;
  itemClassName?: string;
  overlayClassName?: string;
  children?: React.ReactNode;
};

const RoleActionDropdown: FC<Props> = ({
  project,
  role,
  allowSendInvitation = false,
  className,
  itemClassName = 'action-button',
  overlayClassName = 'role-action-dropdown',
  children,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const openInvitationModal = () => {
    dispatch(
      openModal({
        modal: 'invite-casting',
        config: {
          context: {
            mode: 'new',
            multipleInvite: true,
            roleId: role.id,
            hirerId: project.hirerId,
            projectId: project.id,
            projectTitle: project.title,
            roleName: role.name,
          },
        },
      })
    );
  };

  return (
    <Dropdown
      className={className}
      overlayClassName={overlayClassName}
      menu={{
        items: [
          {
            label: <Text>Edit Role</Text>,
            icon: <EditOutlined />,
            onClick: () =>
              history.push(`/hirer/edit/project/${project.id}/role/${role.id}`),
            key: 'edit',
            className: itemClassName,
          },
          ...(allowSendInvitation
            ? [
                {
                  label: <Text>Invite Casting By Batch</Text>,
                  icon: <VideoCameraOutlined />,
                  onClick: openInvitationModal,
                  key: 'invite',
                  className: itemClassName,
                },
              ]
            : []),
        ],
      }}
      trigger={['click']}
    >
      {children}
    </Dropdown>
  );
};

export default RoleActionDropdown;
