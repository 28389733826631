import React, { FC, useEffect, useRef, useState } from 'react';
import { Button, Div } from '../../framework';
import { Typography } from 'antd';
import {
  setInstallBannerDismissed,
  setCountRejectPromptInstall,
  setForceDisplayPrompt,
} from '../../store/app.slice';
import { CloseOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';

import './InstallBanner.scss';

const { Text } = Typography;

const InstallBanner: FC = () => {
  const dispatch = useDispatch();

  const {
    isPWA,
    isInstallBannerDismissed,
    countRejectPromptInstall,
    isForceDisplayPrompt,
  } = useSelector((state: RootState) => state.app);

  const { authUser } = useSelector((state: RootState) => state.user);

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [isShowBanner, setShowBanner] = useState(true);

  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (authUser.id && !isLoggedIn) {
      setIsLoggedIn(true);
    } else if (!authUser.id && isLoggedIn) {
      setIsLoggedIn(false);
    }
  }, [authUser]);

  useEffect(() => () => clearTimeout(timeoutRef.current), []);

  const dismissBanner = (isDismissed: boolean) => {
    dispatch(
      setCountRejectPromptInstall({ count: countRejectPromptInstall + 1 })
    );

    dispatch(setForceDisplayPrompt({ isForceDisplay: false }));

    dispatch(setInstallBannerDismissed({ isDismissed }));
  };

  if (
    isForceDisplayPrompt ||
    (countRejectPromptInstall < 3 &&
      !isInstallBannerDismissed &&
      !isPWA &&
      isLoggedIn)
  ) {
    return (
      <Div
        flex
        mb='s'
        noGrow
        className={`component-banner ${isShowBanner ? '' : '--hide'}`}
      >
        <Div
          style={{
            display: 'flex',
            alignItems: 'center',
            color: 'white',
            paddingRight: '8px',
          }}
          noGrow
        >
          <CloseOutlined
            onClick={() => {
              setShowBanner(false);
              // let animation finish before removing from DOM
              timeoutRef.current = setTimeout(() => dismissBanner(true), 500);
            }}
          />
        </Div>
        <Div style={{ display: 'flex', alignItems: 'center' }} noGrow>
          <img
            className='logo'
            src='https://heycastme-assets.s3.ap-southeast-1.amazonaws.com/logo_shaded.png'
            alt='heycast.me logo'
          />
        </Div>
        <Div
          style={{ display: 'flex', alignItems: 'center', padding: '0 10px' }}
        >
          <Text className='name'>
            Don’t miss out on your job updates. Get real-time notifications on
            our app!
          </Text>
        </Div>
        <Div
          style={{ display: 'flex', alignItems: 'center' }}
          className='burger-menu'
          noGrow
        >
          <Button
            to='/talent/prompt-install'
            onClick={() => {
              setShowBanner(false);
              // let animation finish before removing from DOM
              timeoutRef.current = setTimeout(() => dismissBanner(true), 500);
            }}
          >
            Install App
          </Button>
        </Div>
      </Div>
    );
  }

  return null;
};

export default InstallBanner;
