import React, { FC, useEffect, useState } from 'react';
import { Form, Input, Button, FormProps, Select } from 'antd';
import { Div } from '../../framework';
import { HirerCompany } from '../../helper/type';

export type ProfileInfoValues = {
  describeAs: string;
  describeAsOthers?: string;
  companyWebsite?: string;
  facebook?: string;
  instagram?: string;
  twitter?: string;
  tiktok?: string;
  xiaohongshu?: string;
};

type Props = FormProps & {
  onFinish?: (values: ProfileInfoValues) => void;
  formError?: string;
  values?: Partial<HirerCompany>;
};

const ProfileInfo: FC<Props> = ({
  formError,
  values,
  form: propForm,
  ...props
}) => {
  const [form] = Form.useForm();
  const currentForm = propForm ?? form;

  const [describeAs, setDescribeAs] = useState<string>();

  useEffect(() => {
    if (values) {
      currentForm.setFieldsValue(values);
      setDescribeAs(values.describeAs);
    }
  }, [values]);

  return (
    <Form
      {...props}
      layout='vertical'
      form={currentForm}
      className='hirer-form-profile-info'
    >
      <Form.Item
        name='describeAs'
        label="I'm best described as a..."
        rules={[
          {
            required: true,
            message: 'Please select one that best describe your company.',
          },
        ]}
      >
        <Select
          onChange={(value) => setDescribeAs(value)}
          options={[
            'Production House',
            'Creative Agency',
            'Brand Owner',
            'Digital Marketing Agency',
            'Film Production',
            'Advertiser',
            'Casting House',
            'Film Student',
            'Others',
          ].map((opt) => ({
            label: opt,
            value: opt,
          }))}
          placeholder='Select one'
        />
      </Form.Item>

      <Form.Item
        hidden={currentForm.getFieldValue('describeAs') !== 'Others'}
        name='describeAsOthers'
        label='Please specify'
        rules={[
          {
            required: currentForm.getFieldValue('describeAs') === 'Others',
            message: 'Please specify',
          },
        ]}
      >
        <Input placeholder='How to describe your company' />
      </Form.Item>

      <Div pb='m'>
        <hr style={{ border: 0, borderTop: '1px solid #A4A4A4' }} />
      </Div>

      <Form.Item name='companyWebsite' label='Company Website'>
        <Input placeholder='www.your-website.com' />
      </Form.Item>

      <Form.Item name='facebook' label='Facebook'>
        <Input placeholder='www.facebook.com/your-name' />
      </Form.Item>

      <Form.Item name='instagram' label='Instagram'>
        <Input placeholder='www.instagram.com/your-name' />
      </Form.Item>

      <Form.Item name='twitter' label='X'>
        <Input placeholder='www.x.com/your-name' />
      </Form.Item>

      <Form.Item name='tiktok' label='TikTok'>
        <Input placeholder='www.tiktok.com/your-name' />
      </Form.Item>

      <Form.Item name='xiaohongshu' label='小红书'>
        <Input placeholder='www.xiaohongshu.com/user/profile/111111111' />
      </Form.Item>

      <Form.Item>
        <Div style={{ color: 'red' }}>{formError}</Div>
        <Button type='primary' htmlType='submit' size='large' block>
          Save Changes
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ProfileInfo;
