import React, { FC } from 'react';
import { Typography } from 'antd';
import { Div } from '../../framework/Div/Div';
import { createWaLink } from '../../helper/message';
import './Footer.scss';

const { Paragraph, Title } = Typography;

const Footer: FC = () => {
  return (
    <Div noGrow className='component-footer'>
      <Title style={{ color: '#fff', textAlign: 'center' }}>
        hello@heycast.me
      </Title>
      <Paragraph style={{ color: '#fff' }}>TEL: +6011 5411 0075</Paragraph>
      <Div>
        <a href='https://www.instagram.com/heycast.me' target='_'>
          <img
            className='social-media-icon'
            src='https://heycastme-assets.s3.ap-southeast-1.amazonaws.com/instagram-icon.png'
            width='30'
            height='30'
          />
        </a>
        <a
          href='https://m.facebook.com/profile.php/?id=100086525612903'
          target='_'
        >
          <img
            className='social-media-icon'
            src='https://heycastme-assets.s3.ap-southeast-1.amazonaws.com/facebook-icon.png'
            width='30'
            height='30'
          />
        </a>
        <a
          href={createWaLink("Hi, I'm interested to post a job at heycast.me.")}
          target='_'
        >
          <img
            className='social-media-icon'
            src='https://heycastme-assets.s3.ap-southeast-1.amazonaws.com/whatsapp-icon.png'
            width='30'
            height='30'
          />
        </a>
      </Div>

      <Paragraph style={{ color: '#fff' }}>
        Copyright © {new Date().getFullYear()} HeyCast. All Rights Reserved.
      </Paragraph>
    </Div>
  );
};

export default Footer;
