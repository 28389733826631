import React, { FC, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Avatar,
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Spin,
  RefSelectProps,
  FormProps,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { CaretDownOutlined, PlusOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment';
import { internationalCodes } from '../../../../constant/region';
import { Div } from '../../../framework';
import { Activity, Applicant } from '../../../helper/type';
import { HIRER_ROUTES } from '../../../route/constant';
import { RootState } from '../../../store/store';
import AddressInput from '../../AddressInput/AddressInput';
import InteractiveMap from '../../InteractiveMap/InteractiveMap';

import './InviteCastingForm.scss';

const { Option } = Select;

export type InviteCastingFormValues = {
  castingBrief: string;
  selectedApplicants?: string[];
} & (
  | {
      castingType: 'virtual';
      duration: Activity['duration'];
      scheduleId: string;
      virtualMeetingLink: string;
    }
  | {
      castingType: 'physical';
      duration: Activity['duration'];
      scheduleId: string;
      picContact: string;
      picContactPrefix: string;
      castingLocation: string;
      castingLocationLng: number;
      castingLocationLat: number;
    }
  | {
      castingType: 'self';
      submitDeadline: Moment;
    }
);

type Props = FormProps & {
  mode: 'new' | 'edit';
  hirerId: string;
  roleId: string;
  multipleInvite: boolean;
  castingType?: Activity['castingType'];
  applicants: Applicant[];
  scheduleError?: string;
  onFinish?: (values: InviteCastingFormValues) => void;
  onLoadSchedules: () => Promise<void>;
  onApplicantDropdownVisibleChange?: (open: boolean) => Promise<void>;
  onLoadMoreApplicant?: () => Promise<Applicant[]>;
  isAllowInvite: (applicant: Applicant) => boolean;
  onCancel?: () => void;
};

const InviteCastingForm: FC<Props> = ({
  mode,
  hirerId,
  roleId,
  multipleInvite,
  form: propForm,
  castingType: propCastingType = 'virtual',
  applicants,
  scheduleError,
  onFinish,
  onLoadSchedules,
  onApplicantDropdownVisibleChange,
  onLoadMoreApplicant,
  isAllowInvite,
  onCancel,
}) => {
  const [defaultForm] = Form.useForm();
  const selectScheduleRef = useRef<RefSelectProps>(null);
  const { loadStack } = useSelector((state: RootState) => state.app);

  const { mappedSchedules, mappedApplicants } = useSelector(
    (state: RootState) => state.hirer
  );

  const [castingType, setCastingType] = useState(propCastingType);
  const [mapPin, setMapPin] = useState<{ lng: number; lat: number }>();

  const { hasLoadOtherApplicants } = mappedApplicants[roleId] ?? {};

  const isLoadingSchedule = loadStack.includes(`activity/schedules/${hirerId}`);
  const isLoadingApplicant =
    loadStack.includes(`job/applicant/list?isRecommended=true`) ||
    loadStack.includes(`job/applicant/list?isRecommended=false`);

  const form = propForm ?? defaultForm;

  const handleIncludeAllShortlisted = async () => {
    let moreApplicants;
    if (!hasLoadOtherApplicants) {
      moreApplicants = await onLoadMoreApplicant?.call(null);
    }

    const toInclude = applicants
      .concat(moreApplicants ?? [])
      .filter((appl) => appl.status === 'shortlisted' && isAllowInvite(appl))
      .map((appl) => appl.talentId);
    const selected: string[] = form.getFieldValue('selectedApplicants') ?? [];

    const allSelected = Array.from(new Set([...selected, ...toInclude]));
    form.setFieldValue('selectedApplicants', allSelected);
    await form.validateFields();
  };

  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={onFinish}
      className='component-invite-casting-form'
    >
      {mode === 'new' && (
        <Form.Item
          name='castingType'
          label='Casting Type'
          initialValue={castingType}
          rules={[{ required: true, message: 'Please choose a casting type.' }]}
        >
          <Radio.Group
            buttonStyle='solid'
            onChange={(e) => setCastingType(e.target.value)}
          >
            <Radio.Button value='physical'>Physical</Radio.Button>
            <Radio.Button value='virtual'>Virtual</Radio.Button>
            <Radio.Button value='self'>Self</Radio.Button>
          </Radio.Group>
        </Form.Item>
      )}

      {mode === 'edit' && (
        <Form.Item
          name='castingType'
          hidden
          initialValue={castingType}
        ></Form.Item>
      )}

      <Form.Item
        name='castingBrief'
        label='Casting Brief'
        rules={[{ required: true, message: 'Missing brief.' }]}
      >
        <TextArea
          rows={4}
          placeholder='What should applicant prepare for this casting session?'
        />
      </Form.Item>

      {castingType !== 'self' && mode === 'new' && (
        <>
          <Form.Item
            name={['duration', 'value']}
            label='How long is the casting session?'
            rules={[{ required: true, message: 'Please input number.' }]}
          >
            <InputNumber
              min={1}
              placeholder='etc. 30 (minutes)'
              addonAfter={
                <Form.Item
                  noStyle
                  name={['duration', 'period']}
                  initialValue='minute'
                >
                  <Select>
                    <Option value='minute'>minute</Option>
                    <Option value='hour'>hour</Option>
                  </Select>
                </Form.Item>
              }
            />
          </Form.Item>
          <Form.Item
            name='scheduleId'
            label='Select a schedule, talent will prompt to choose a timeslot within the schedule:'
            className='mb-0'
            rules={[{ required: true, message: 'Missing schedule.' }]}
          >
            <Select
              ref={selectScheduleRef}
              showSearch
              placeholder='Select a schedule'
              onDropdownVisibleChange={async (open: boolean) => {
                if (open) {
                  await onLoadSchedules();
                }
              }}
              options={Object.keys(mappedSchedules).map((scheduleId) => ({
                label:
                  mappedSchedules[scheduleId].scheduleName ??
                  Object.keys(mappedSchedules[scheduleId].slots).sort(
                    (s1, s2) => s2.localeCompare(s1)
                  )[0],
                value: scheduleId,
              }))}
              dropdownRender={(menu) => (
                <>
                  <Spin spinning={isLoadingSchedule}>{menu}</Spin>
                  <Divider style={{ margin: 0 }} />
                  <Div p='m'>
                    <Link
                      target='_blank'
                      to={HIRER_ROUTES.SCHEDULE_CREATE}
                      onClick={() => selectScheduleRef.current?.blur()}
                    >
                      <PlusOutlined style={{ marginRight: '0.5rem' }} />
                      Create New
                    </Link>
                  </Div>
                </>
              )}
            />
          </Form.Item>
          <Div className='schedule-error'>{scheduleError}</Div>
        </>
      )}

      {castingType === 'physical' && (
        <>
          <Form.Item
            name='picContact'
            label='Contact of person in charge'
            rules={[
              {
                required: true,
                message: 'Please provide a valid contact number.',
              },
              {
                validator: (_, value) => {
                  if (value) {
                    const prefix = form.getFieldValue('picContactPrefix');
                    const validRegex =
                      prefix === '+60'
                        ? /^(0?1)[0-46-9]-*[0-9]{7,8}$/
                        : /^[0-9]{6,12}$/;
                    if (!value.match(validRegex)) {
                      return Promise.reject(
                        "This contact number doesn't seems right."
                      );
                    }
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              addonBefore={
                <Form.Item
                  name='picContactPrefix'
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: 'Please select phone number prefix.',
                    },
                  ]}
                  initialValue={'+60'}
                >
                  <Select style={{ width: 150 }}>
                    {internationalCodes.map((code) => (
                      <Option
                        key={`${code.prefix} (${code.name})`}
                        value={code.prefix}
                      >{`${code.prefix} (${code.name})`}</Option>
                    ))}
                  </Select>
                </Form.Item>
              }
              placeholder='Mobile Number'
              type='number'
            />
          </Form.Item>
          <Form.Item
            name='castingLocation'
            label='Casting Location Address'
            rules={[
              {
                required: true,
                message: 'Please provide address of the casting location.',
              },
            ]}
          >
            <AddressInput
              placeholder='Location Address'
              onSelectAddress={(value) => {
                setMapPin({
                  lng: value.lng,
                  lat: value.lat,
                });
                form.setFieldValue('castingLocation', value.address);
                form.setFieldValue('castingLocationLng', value.lng);
                form.setFieldValue('castingLocationLat', value.lat);
              }}
            />
          </Form.Item>
          <Form.Item name='castingLocationLng' hidden>
            <Input />
          </Form.Item>
          <Form.Item name='castingLocationLat' hidden>
            <Input />
          </Form.Item>
          <Form.Item>
            <Div className='map-location-address'>
              <InteractiveMap
                pin={
                  mapPin || {
                    lng: form.getFieldValue('castingLocationLng'),
                    lat: form.getFieldValue('castingLocationLat'),
                  }
                }
                onPinMap={(value) => {
                  form.setFieldValue('castingLocation', value.address);
                  form.setFieldValue('castingLocationLng', value.lng);
                  form.setFieldValue('castingLocationLat', value.lat);
                }}
              />
            </Div>
          </Form.Item>
        </>
      )}

      {castingType === 'virtual' && (
        <Form.Item
          name='virtualMeetingLink'
          label='Virtual Meeting Link (Zoom, Google Meet, Microsoft Team)'
          rules={[
            { required: true, message: 'Missing meeting link.' },
            {
              type: 'url',
              message: "This doesn't look like a url.",
            },
          ]}
        >
          <Input placeholder='etc. https://meet.google.com/xxx-xxxx-xxx' />
        </Form.Item>
      )}

      {castingType === 'self' && (
        <Form.Item
          name='submitDeadline'
          label='Casting Video Submission deadline'
          rules={[
            {
              required: true,
              message: 'Please set a deadline for casting video submission.',
            },
          ]}
        >
          <DatePicker
            disabledDate={(current) =>
              current && current < moment().endOf('day')
            }
            placeholder='Set a submission deadline'
            format={'DD MMM yyyy'}
          />
        </Form.Item>
      )}

      {mode === 'new' && multipleInvite && (
        <>
          <Form.Item
            name='selectedApplicants'
            label='Invite Applicants'
            className='mb-0'
            rules={[
              {
                required: true,
                message: 'Please select at least 1 applicant to invite.',
              },
              {
                validator: (_, items) => {
                  if (items && items.length > 12) {
                    return Promise.reject(
                      'Maximum can only invite 12 applicants at once.'
                    );
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Select
              showSearch
              mode='multiple'
              size='large'
              placeholder='Select applicants'
              onDropdownVisibleChange={onApplicantDropdownVisibleChange}
              dropdownRender={(menu) => (
                <>
                  <Spin spinning={isLoadingApplicant}>{menu}</Spin>
                  {!hasLoadOtherApplicants && (
                    <>
                      <Divider style={{ margin: 0 }} />
                      <Div p='xs'>
                        <Button
                          type='link'
                          onClick={onLoadMoreApplicant}
                          disabled={isLoadingApplicant}
                        >
                          <CaretDownOutlined
                            style={{ marginRight: '0.5rem' }}
                          />
                          Load Other Applicants
                        </Button>
                      </Div>
                    </>
                  )}
                </>
              )}
            >
              {applicants.filter(isAllowInvite).map((applicant) => (
                <Option key={applicant.talentId} value={applicant.talentId}>
                  <Div flex style={{ alignItems: 'center' }}>
                    <Avatar src={applicant.profilePhotoThumbnail} />
                    <span style={{ marginLeft: '0.5rem' }}>
                      {applicant.name}
                    </span>
                  </Div>
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Button
            type='ghost'
            icon={
              isLoadingApplicant && (
                <Spin size='small' style={{ marginRight: '1rem' }} />
              )
            }
            disabled={isLoadingApplicant}
            style={{ marginTop: '0.25rem', marginBottom: '0.5rem' }}
            onClick={handleIncludeAllShortlisted}
          >
            Include all shortlisted talents
          </Button>
        </>
      )}

      <Form.Item>
        <Button block type='primary' htmlType='submit' className='btn-submit'>
          {mode === 'edit' ? 'Save Changes' : 'Send Invitation'}
        </Button>
        <Button block onClick={onCancel}>
          Cancel
        </Button>
      </Form.Item>
    </Form>
  );
};

export default InviteCastingForm;
